/* ======================= UI CHANGES ======================= */

@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Montserrat');

@-moz-document url-prefix(){
    main div{
        overflow: auto;
    }
}
.login-alert-box{
   height: 4rem;
}
.forgot-password{
    text-align: right;
    margin-right: 10px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 5px;

}
.buttons {
    margin: 10%;
    text-align: center;
}
.btn-hover {
    width: 23rem;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 0;
    height: 26px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    font-family: 'Montserrat', sans-serif;
}
.btn-hover:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.btn-hover:focus {
    outline: none;
}
.btn-hover.color-11 {
       background-image: linear-gradient(to right, #e51220, #f15e64, #e14e53, #e2373f);
       
}
.btn-group-recover{
    margin: 1rem 2rem 3rem 2rem !important;
}
.password-recover-container{
    width: 40rem;
}
.margin-bottom-15{
    margin-bottom: 15px; 
}
.validation-box{
    display: flex;
    margin-top: 0;
    color: red;
    padding-bottom: 4px;
    padding-top: 1px;
}
.validation-hidden{
    color: white;
    padding-right: 7em;
   
}
.validation-hidden-recover{
    color: #f1f1f1;
    padding-right: 7em;
}
.validation-hidden-bottom{
    color: white;
    padding-right: 7em;
 }

.font-size-24{
    font-size: 24px !important;
}
.image-width-10{
    width: 11rem !important;
}
.mb-3 {
    margin-bottom: 3rem !important;
}
.mx-3{
    margin: 0 3rem 0 3rem !important;
}
.width-45{
    width: 45rem !important;
}
.mb-2{
    margin-bottom: 2rem !important;
}
.no-margin-padding{
    margin-top: 0 !important;

}

.mb-0{
    margin-bottom: 0 !important;
}
.mt-4 {
    margin-top: 4rem !important;
}
.mt-2 {
    margin-top: 2rem !important;
}
.mt-5{
     margin-top: 5rem !important;
 }
.mt-3{
    margin-top: 3rem !important;
}
.mt-1 {
    margin-top: 1rem !important;
}
.mt-8 {
    margin-top: 8rem !important; 
}
.panel-footer{
    width: 100% !important;
    padding: 7px 15px;
    background-color: #6b6d73;
    border-top: 1px solid #525458;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    color: #ffe8ea;
}
.login-link{
    color: #ccc !important; 
}
.border-black {
    border: 1px solid black;
}
.login-button{
    outline: none !important;
    align-items: center;
    border-radius: inherit;
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    white-space: nowrap;
    width: 12rem;
    height: 3rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    background-color: #1867c0!important;
    border-color: #1867c0!important;
}
.login-input-form{
    padding-left: 10px;
    padding-right: 10px;
 }
.logo-image{
    width: 13rem;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-top: 8em;
 }
.logo-image-recovery {
    width: 13rem;
}
.myFlex{
    display: flex;
}
.login-form-text {
    padding: 0px 10px 0px 16px;
    margin-top: 85px;
    color: #999;
    font-size: 25px;
    font-family: 'Montserrat', sans-serif;
    
}
.login-form-text-recovery {
    text-align: center;
    margin-top: 9rem;
    color: #999;
    font-size: 27px;
    font-family: 'Montserrat', sans-serif;

}
.login-subtext{
    font-size: 11px;
    color: white;
    margin-top: 1rem;
    text-align: center;
    font-weight: 200;
    letter-spacing: 2px;
    word-spacing: 2px;
    font-family: 'Montserrat', sans-serif;
}
.pad-0{
    padding: 0 !important;
}
.bright-green{
    color: #54dc35 !important;
}
.special-link{
    color: #44887d !important;
}
.galaxy-only{
    background: url("../img/galaxy.jpeg");
    background-size: cover;
    background-position: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15rem !important;
 }
 .panel-logo {
    height: 31rem;
    width: 37rem;
    background: url("../img/galaxy.jpeg");
    background-size: cover;
    background-position: 40%;
  
 }
 .panel-logo-recover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37rem;
    background: url("../img/galaxy.jpeg");
    background-size: cover;
    background-position: 40%;
  
 }
.panel-reset-password{
    margin-top: 2rem;
    margin-bottom: 0rem;
    height: auto;
    padding: 15px;
}
 .panel-login {
    width: 30rem;
    height: 31rem;
    padding-right: 15px;
    padding-left: 15px;
    }

 .padding-top-8{
     padding-top: 8rem !important;
 }
 .panel-border{
     border: 1px #333333cf solid;
 }
 .border-right{
     border-right: 1px lightgrey solid;
 }

 .border-left {
     border-left: 1px lightgray solid;
 }


/* Auction CSS */
.auction-box-parent{
    display: flex;
    justify-content: center;
    align-items: center;
}
.auction-holder{
    height: 80px;   
}

input, select{
    box-sizing: inherit;
    /* border: 1px solid #cfc1e2 !important; */
    box-shadow: none;
    /* background-color: #eafffd !important; */
}

input[type="radio"], input[type="checkbox"]{
    margin: 0;
    margin-top: 1px \9;
    line-height: normal;
}
 input:focus,  select:focus{
    outline: none !important;
    background-color: rgba(162, 247, 200, 0.4) !important;
    box-shadow: 0 0px 5px rgb(162, 247, 200)  !important;
    border: 1px solid rgb(149, 226, 218)  !important;
}
.btn-primary{
    background: #38878f none !important;
    border: 1px solid #28a5cd !important;
    /* box-shadow: none; */
}
.btn-primary:hover {
    background: #45a5af !important;
    transition: background 0.4s, color 0.4s;
    -o-transition: background 0.4s, color 0.4s;
    -ms-transition: background 0.4s, color 0.4s;
    -moz-transition: background 0.4s, color 0.4s;
    -webkit-transition: background 0.4s, color 0.4s;;
}
.btn:focus {
    outline: none !important;
  }

.panel-link{
    color: #54dc35 !important;
}


.pagination > .active > a{
    background-color: #44887d !important;
    border-color: #ccc;
}



.borderless td, .borderless th {
    border-top: 1px solid #dddddd42!important ;
}
.panel{
    border: 1px solid #998ea7bf!important;
    border-radius: 0 !important;
    margin: 0 !important;
    position: relative;
    -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.2) inset !important;
    -moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.2) inset !important;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.2) inset !important;
 }

.panel-heading{
    background: #44887d !important;
    color: white;
    font-weight: normal !important;
    font-size: 10pt;
    border-radius: 0 !important;
    border-bottom: 1px solid #bcbbbd !important;
}

.col-centered {
    float: none;
    margin: 0 auto;
}

.panel-heading:hover{
    background-image: none !important;
    background-color: #ddd;
}

.panel-default{
    
    margin-right: 5px !important;
    margin-left: 5px !important;
    margin-bottom: 5px !important;
 }

 .panel-default-no-margin {
    margin-right: 0px !important;
    margin-left: 0px !important;
 }

.panel-body{
    padding: 10px 10px 0 10px !important;
    margin: 0 !important;

}

.panel-body-title{
    font-size: 16pt;
    padding: 5px 15px;
    border-radius: 5px;
    background-color: #ffffff;
    margin-bottom: 10px;
    text-align: center;
    color: #586061;
}





.btn-default a:link{
    color: #fff;
}

.site-container{
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
}

.table{
    margin-top: 10px !important;
}

.table .table-striped td{
    font-weight: normal;
}

.table > thead > tr > th{
    line-height: 1 !important;
}

.table > thead > tr > th{
    padding: 2px 5px 2px 5px !important;
    border-top: 0 solid #ddd;
}

.table > tbody > tr > td{
    padding: 3px 5px 3px 5px !important;
    vertical-align: middle;
}

.table > tbody .selected{
    background-color: #44887d !important;
    color: #fff;
}

.table-hover > tbody > tr:not(.table-sublist):hover{
    cursor: pointer;
    color: #fff;
    background-color: #44887d !important;
}

.table-hover > tbody > tr:hover a:hover{
    color: #fff;
}

.table-hover > tbody > tr:hover a:visited{
    color: #fff;
}

.table > thead > tr > th:not(.table-sublist):hover{
    cursor: pointer;
    color: #333;
}

.table-hover > tbody > tr.clm{
    cursor: auto;
    color: #999;
    background-color: #eee !important;
}

.table-hover > tbody > tr.clm:hover{
    cursor: pointer;
    color: #999;
    background-color: #eee !important;
}

input[type="text"].ui-grid-filter-input, .ui-grid-filter-select {
    padding: 5px !important;
    margin: 0 !important;
    border: 0 !important;
    width: 100% !important;
    border-radius: 3px !important;
    background-color: #fff !important;
    border: 1px solid #d4d4d4 !important;
}

.main-panel{

    background-color: rgba(51, 51, 51, 0.6);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 10px;
    height: 100%;
    display: table;
    border: 1px solid #d3d3d3;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
}

.breadcrumb{
    display: flex;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #d3d3d3;
    margin-bottom: 0;
    padding: 0;
}

.breadcrumb div{
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
}

.breadcrumb ol{
    list-style: none;
    margin-bottom: 0;
    padding-top: 8px;
    padding-bottom: 5px;
    padding-left: 10px;
    border: 0 !important;
}

.breadcrumb ol > li{
    display: inline;
}

.breadcrumb > ol > li + li:before{
    padding: 0 5px;
    display: inline-block;
    color: #333;
    content: "/\00a0";

}


.breadcrumb a{
    padding-right: 7px;
}

.breadcrumb > .active{
    color: #777;
}

fieldset{
    display: block;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-before: 0;
    -webkit-padding-start: 0;
    -webkit-padding-end: 0;
    -webkit-padding-after: 0;
    border: none;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-repeat: initial;
    min-width: -webkit-min-content;
    width: 100%;
}

#loading-bar-spinner{
    display: block !important;
    color: #44887d;
    margin: auto auto !important;
    top: 40vh !important;
    left: 25vw;
    width: 50vw !important;
    text-align: center !important;
    z-index: 99999 !important;
    font-size: 30pt !important;
    background-color: #333;
    border-radius: 5px;
    padding: 20px;

}

#loading-bar .bar{
    -webkit-transition: width 350ms !important;
    -moz-transition: width 350ms !important;
    -o-transition: width 350ms !important;
    transition: width 350ms !important;
    display: block !important;
    background: #44887d !important;
    z-index: 100020333366 !important;
    margin: auto;
    height: 10px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

.label-default{
    background-color: #777;
    padding: 8px;
    font-size: 12px;
    font-weight: 400 !important;
}

.label{
    margin: 1px !important;
    padding: 5px !important;
    font-size: 10px;
    font-weight: 400 !important;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0;
    display: inline-block;
}

/*TABS*/
.tab-content h3,
h2,
h1:first-child{
    margin-top: 0;
}

.shadow.tab-content{
    border-bottom: 1px solid #dadada;
    border-left: 1px solid #dadada;
    border-right: 1px solid #dadada;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.13);
}

/*Accordion*/
.accordion{
    margin-bottom: 0 !important;
}

.accordion .panel-heading{
    background: #fff;
    padding: 0;

}

.accordion .panel-heading a{
    font-size: 15px;
    padding: 17px 16px;
    display: block;
    color: #44887d;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

}

.accordion .panel-heading a .fa{
    transition: transform 200ms ease-in-out;
    margin-right: 10px;
    transform: rotate(90deg);
}

.accordion .panel-heading a.collapsed{
    color: #555;
}

.accordion .panel-heading a.collapsed .fa{
    transform: rotate(0deg);
}

.accordion .panel-heading + .panel-collapse .panel-body{
    border-top: 0;
    padding: 3px 20px 20px 34px;

}

.accordion .panel{
    border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 0 !important;
    margin-bottom: 5px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);

}

.accordion .panel:first-child{
    border-top: 0;
}

.accordion.panel-group .panel + .panel{
    margin-top: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

/*Primary color accordion*/
.accordion.accordion-color .panel-heading a{
    background-color: #44887d;
    color: #fff;
}

.accordion.accordion-color .panel-heading a.collapsed{
    background-color: #fff;
    color: #555;
}

.accordion.accordion-color .panel-collapse .panel-body{
    background-color: #44887d;
    color: #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

/*Semi color accordion*/
.accordion.accordion-semi .panel-heading a{
    background-color: #44887d;
    color: #fff;
    transition: background-color 200ms ease-in-out;
    border-bottom: none !important;

}

.accordion.accordion-semi .panel-heading.success a{
    background-color: #60c060;
    border-bottom: 1px solid #54a754;
}

.accordion.accordion-semi .panel-heading.warning a{
    background-color: #fc9700;
    border-bottom: 1px solid #d68000;
}

.accordion.accordion-semi .panel-heading.danger a{
    background-color: #da4932;
    border-bottom: 1px solid #ca452e;
}

.accordion.accordion-semi .panel-heading a.collapsed{
    background-color: #fff;
    color: #555;
    border-bottom: 0;
}

.accordion.accordion-semi .panel-collapse .panel-body{
    padding: 15px 20px 20px 34px;
}

.md-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    max-width: 630px;
    min-width: 320px;
    height: auto;
    z-index: 2000;
    visibility: hidden;
    backface-visibility: hidden;
    transform: translateX(-50%) translateY(-50%);
}

.md-show{
    visibility: visible;
}

.md-overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    background: rgba(143, 27, 15, 0.8);
    transition: all 0.3s;
}

.md-show ~ .md-overlay{
    opacity: 1;
    visibility: visible;
}

.md-perspective,
.md-perspective body{
    height: 100%;
    overflow: hidden;
}

.md-perspective body{
    background: #222;
    perspective: 600px;
}

.container{

    min-height: 100%;
}

.container-orders {
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

.modal-content{
    padding: 10px;

    background-color: #fff !important;

}

.modal-header{
    min-height: 16px;
    padding: 10px 15px;
    border-bottom: 1px solid #e5e5e5;
    background-color: #f1f1f1;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.modal-container{
    border: 1px solid #e5e5e5;
    border-radius: 3px;
}

.close{
    padding: 5px !important;
}

.modal-footer{
    padding: 10px;
    background-color: #f1f1f1;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.modal-body{
    min-height: 200px;
}

.modal-backdrop{
    background-color: dimgrey;
}

.modal-backdrop.in{
    filter: alpha(opacity=50);
    opacity: .5;
}

.loginModal .modal-dialog{
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
}

.panel .item{
    padding: 5px;

}

.panel .item:hover{
    background-color: #f4f4f4;
    border-radius: 5px;

}

.panel .item a{
    text-decoration: none;
    font-weight: bold;
}

/*Flot Charts*/
.blue-chart .flot-text,
.red-chart .flot-text{
    color: #fff !important;
}

.flot-text{
    font-size: 10px !important;
}

.legend table td{
    font-size: 11px !important;
}

.blue-chart{
    border: 1px solid #258fec;
    border-top-color: #499de5;
    border-bottom-color: #1e73bd;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#52aeff), to(#2180d3));
    /* Chrome, Safari 4+ */
    background-image: -webkit-linear-gradient(top, #52aeff, #2180d3);
    /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image: -o-linear-gradient(top, #52aeff, #2180d3);
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, #52aeff, #2180d3);
    background-color: #3290e2;
}

.block-flat .blue-chart{
    padding: 20px !important;
}

.blue-chart h2{
    text-shadow: 0 -1px 0 #357fc0;
}

.easyPieChart{
    display: inline-block;
}

.red-chart{
    border: 1px solid #f27b56;
    border-top-color: #ee815e;
    border-bottom-color: #d66543;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8a65), to(#e26a45));
    /* Chrome, Safari 4+ */
    background-image: -webkit-linear-gradient(top, #ff8a65, #e26a45);
    /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image: -o-linear-gradient(top, #ff8a65, #e26a45);
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, #ff8a65 45%, #e26a45 102%);
    background-color: #3290e2;
}

.red-box{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8a65), to(#f07853));
    /* Chrome, Safari 4+ */
    background-image: -webkit-linear-gradient(top, #ff8a65, #f07853);
    /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image: -o-linear-gradient(top, #ff8a65, #f07853);
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, #ff8a65 45%, #f07853 102%);
    background-color: #ff8a65;
    color: #fff;
}

.red2-box{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff7877), to(#ed5554));
    /* Chrome, Safari 4+ */
    background-image: -webkit-linear-gradient(top, #ff7877, #ed5554);
    /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image: -o-linear-gradient(top, #ff7877, #ed5554);
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, #ff7877 45%, #ed5554 102%);
    background-color: #ff7877;
    color: #fff;
}

.blue-box{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#52aeff), to(#3d9cef));
    /* Chrome, Safari 4+ */
    background-image: -webkit-linear-gradient(top, #52aeff, #3d9cef);
    /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image: -o-linear-gradient(top, #52aeff, #3d9cef);
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, #52aeff 45%, #3d9cef 102%);
    background-color: #52aeff;
    color: #fff;
}

.blue-box.borders{
    border: 1px solid #258fec;
    border-top-color: #499de5;
    border-bottom-color: #1e73bd;
}

.green-box{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#82c261), to(#70be46));
    /* Chrome, Safari 4+ */
    background-image: -webkit-linear-gradient(top, #82c261, #70be46);
    /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image: -o-linear-gradient(top, #82c261, #70be46);
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, #82c261 45%, #70be46 102%);
    background-color: #82c261;
    color: #fff;
}

.violet-box{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#b450b2), to(#853685));
    /* Chrome, Safari 4+ */
    background-image: -webkit-linear-gradient(top, #b450b2, #853685);
    /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image: -o-linear-gradient(top, #b450b2, #853685);
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, #b450b2 45%, #853685 102%);
    background-color: #82c261;
    color: #fff;
}

.yellow-box{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffc91c), to(#fab219));
    /* Chrome, Safari 4+ */
    background-image: -webkit-linear-gradient(top, #ffc91c, #fab219);
    /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image: -o-linear-gradient(top, #ffc91c, #fab219);
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, #ffc91c 45%, #fab219 102%);
    background-color: #82c261;
    color: #fff;

}

.white-box{
    background: #fff;
}

.widget-block.calendar-box{
    overflow: hidden;
}

.widget-block.calendar-box h2{
    font-size: 28px;
}

.widget-block.calendar-box .day{
    text-align: center;
    font-size: 102px;
}

.widget-block.weather-box{
    overflow: hidden;
    color: #fff;
}

.widget-block.weather-box .photo{
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 260px;
}

.widget-block.weather-box h2{
    margin-top: 60px;
    font-size: 28px;
    font-weight: 400;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
}

.widget-block.weather-box .day{
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 70px;
    margin-top: 3px;
}

.fb-box{
    margin-top: 0 !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#3e80bf), to(#325fa0));
    /* Chrome, Safari 4+ */
    background-image: -webkit-linear-gradient(top, #3e80bf, #325fa0);
    /* Chrome 10-25, iOS 5+, Safari 5.1+ */
    background-image: -o-linear-gradient(top, #3e80bf, #325fa0);
    /* Opera 11.10-12.00 */
    background-image: linear-gradient(to bottom, #3e80bf 50%, #325fa0 102%);
    background-color: #366eab;
    color: #fff;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.35) !important;
}

.fb-box h2{
    font-size: 19px;
    font-weight: 400;
    margin: 0 0 5px 0;
    padding-top: 10px;
}

.fb-box h3{
    font-size: 13px;
    font-weight: 200;
    margin: 0 0 20px 0;
}

.fb-box h4{
    font-size: 13px;
    font-weight: 400;
    margin: 0 0 5px 0;
}

.fb-box img{
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 3px solid #fff;
    margin-bottom: 15px;
    margin-top: 20px;
}

.fb-box .content{
    padding: 20px;
    text-align: center;
}

.fb-box .info{
    background: #264a7e;
    color: #fff;
    border-top: 1px solid #426ca5;
    text-align: center;
}

.fb-box .info button{
    box-shadow: none;
    max-width: 195px;
    width: 80%;
    padding: 13px;
    background: #4884c0;
    border: 0;
    margin: 5px 0;
    text-transform: uppercase;
}

.fb-box .info button i{
    margin-right: 3px;
}

.fb-box .info div{
    padding: 15px 10px;
}

table .button{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: 0;
}

.fb-box .row{
    margin: 0;
}

.row{
    margin: 0 !important;
   
}

.block .cl,
.widget-block .cl{
    margin-bottom: 20px;
}

.widget-block{
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.21);
    border: 0;
    margin-bottom: 30px;
    margin-top: 0;
}

.widget-block .row{
    margin: 0;
}

.widget-block .padding{
    padding: 20px 20px;
}

.widget-block .vpadding{
    padding: 20px 0;
}

.widget-block .hpadding{
    padding: 0 20px;
}

.block .header,
.widget-block .header,
.block-flat .header{
    padding: 10px;
    background-color: #fefefe;
    border-bottom: 1px solid #dadada;
}

.block .header{
    border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    padding: 10px;
}

.block-flat .header{
    background: transparent;
    padding: 3px 0;
}

.block-flat .header.no-border{
    border: 0;
}

.block-flat .header h3{
    margin-top: 3px;
    font-family: "Raleway", Helvetica, sans-serif;
    font-weight: 200;
}

.block-flat .content{
    padding: 15px 3px 0 3px;
}

.block-flat .content.full-width{
    padding: 0;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
}

.block-flat .content h4{
    margin-top: 20px;
    font-size: 16px;
}

.block-flat .content .title{
    margin: 5px 0 15px 0;
}

.block-flat.no-padding .content{
    padding: 0 !important;
}

.legend-container table{
    width: auto;
    margin: 20px 0 0 20px;
}

.legend-container table tr{
    background: transparent;
}

.legend-container table td{
    border: 0;
    padding: 0 5px 3px 5px;
}

.legend-container .legendColorBox > div{
    border: 0 !important;
}

.legend-container .legendColorBox > div > div{
    border-width: 6px !important;
}

.block-flat.no-padding .content table td,
.block-flat.no-padding .content table th{
    padding: 11px 10px 7px 10px;
}

.block .no-border{
    border-bottom: 0;
}

.block .content h2,
.widget-block .content h2{
    font-family: 'Open Sans', sans-serif;
}

.block .header h2,
.widget-block .header h2,
.block-flat .header h2{
    margin: 0;
    font-family: "Raleway", Helvetica, sans-serif;
    font-weight: 100;
    font-size: 29px;
    line-height: 38px;
}

.block .header h3,
.widget-block .header h3{
    margin: 0;
    font-size: 13px;
    font-weight: 200;
    line-height: 21px;
    color: #666;
}

.block .header .actions i,
.widget-block .header .actions i{
    font-size: 18px;
    color: #6f6f6f;
    display: inline-block;
    padding: 0;
    text-align: center;
    cursor: pointer;
    width: 20px;
    vertical-align: top;
}

.block .header .actions i:hover,
.widget-block .header .actions i:hover{
    color: #858585;
}

.widget-block .fb-head{
    background: #1784c7;
    border: 0;
    color: #fff;
}

.widget-block .fb-head i{
    display: inline-block;
    text-align: center;
    width: 28px;
}

.bg-darkgray{
    background-color: #888e97;
    color: #fff;
}

.bg-blue{
    background-color: #337ab7;
    color: #fff;
}

.bg-blue .caret,
.bg-darkgray .caret{
    border-color: #fff transparent;
    border-width: 5px 5px 0;
}

.bg-blue a,
.bg-darkgray a{
    color: #fff;
}

.cl-mcont .block .content{
    padding: 20px;
}

.cl-mcont .block .stat-data,
.cl-mcont .block-flat .stat-data{
    padding: 10px;
    float: left;
    width: 50%;
}

.cl-mcont .block .stat-data h2,
.cl-mcont .block-flat .stat-data h2{
    margin: 0 0 5px 0;
}

.cl-mcont .block .stat-data span,
.cl-mcont .block-flat .stat-data span{
    font-size: 18px;
}

.cl-mcont .block .stat-data .stat-blue,
.cl-mcont .block-flat .stat-data .stat-blue{
    background: #337ab7;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    text-align: center;
    color: #fff;
    padding: 20px 10px;
}

.cl-mcont .block .stat-data .stat-number,
.cl-mcont .block-flat .stat-data .stat-number{
    border-bottom: 1px solid #dadada;
}

.cl-mcont .block .stat-data .stat-number:last-child,
.cl-mcont .block-flat .stat-data .stat-number:last-child{
    border-bottom: 0;
}

.cl-mcont .block .stat-data .stat-number div,
.cl-mcont .block-flat .stat-data .stat-number div{
    display: inline-block;
    vertical-align: middle;
    padding: 5px 5px;
    font-size: 16px;
    line-height: 20px;
}

.cl-mcont .block .stat-data .stat-number h2,
.cl-mcont .block-flat .stat-data .stat-number h2{
    margin: 0;
    font-size: 35px;
    color: #444;
    font-weight: 300;
}

.cl-mcont .block .stat-data .stat-number span,
.cl-mcont .block-flat .stat-data .stat-number span{
    font-size: 12px;
}

.block .fact-data,
.widget-block .fact-data,
.block-flat .fact-data{
    margin: 25px 0;
    padding: 0 20px;
    display: inline-block;
    width: 49%;
    vertical-align: top;
}

.block .total-data,
.widget-block .total-data,
.block-flat .total-data{
    padding: 15px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    position: relative;
}

.block .dropdown-menu,
.widget-block .dropdown-menu,
.block-flat .dropdown-menu{
    margin: -1px 0 0 0;
    border-radius: 1px;
    -webkit-border-radius: 1px;

}

.btn-group .dropdown-menu{

}

.dropdown-menu{
    margin: 0;
    padding: 10px 0;
}

.dropdown-menu li{
    margin: -6px 3px;
    padding: 0;
}

.dropdown-menu li a{
    padding: 15px 15px !important;
    margin: 0;
    transition: background 0.4s, color 0.4s;
    -o-transition: background 0.4s, color 0.4s;
    -ms-transition: background 0.4s, color 0.4s;
    -moz-transition: background 0.4s, color 0.4s;
    -webkit-transition: background 0.4s, color 0.4s;
}

.dropdown-menu li a:hover{
    padding: 5px;
    background: #5bb3a5 none !important;
    color: #fff;
    border-radius: 3px;
    transition: background 0.4s, color 0.4s;
    -o-transition: background 0.4s, color 0.4s;
    -ms-transition: background 0.4s, color 0.4s;
    -moz-transition: background 0.4s, color 0.4s;
    -webkit-transition: background 0.4s, color 0.4s;
}

.btn-group{
    margin-right: 5px;
    margin-left: 4px;
}

.static-mn,
.widget-block .static-mn{
    display: block;
    position: static;
}

.static-mn,
.widget-block .static-mn{
    display: block;
    position: static;
}

.static-lg-menu .static-mn,
.widget-block .static-lg-menu .static-mn{
    min-width: 90%;
}

.block .items,
.widget-block .items{
    margin: 0;
    padding: 0;
}

.block .items li,
.widget-block .items li{
    border-top: 1px solid #e8e8e8;
    list-style: none;
    font-size: 14px;
    line-height: 17px;
    padding: 13px;
    background-color: #fff;
    transition: background 0.25s ease-in-out;
    -moz-transition: background 0.25s ease-in-out;
    -webkit-transition: background 0.25s ease-in-out;
}

.block .items li i,
.widget-block .items li i{
    color: #555;
    display: block;
    float: left;
    font-size: 28px;
    height: 38px;
    margin-left: 4px;
    margin-right: 15px;
    margin-top: 2px;
}

.block .items li small,
.widget-block .items li small{
    display: block;
}

.block .items li:hover,
.widget-block .items li:hover{
    background-color: #f4f4f4;
}

.block .items li .value,
.widget-block .items li .value{
    font-size: 15px;
    margin-top: 7px;
}

.block .items li:first-child,
.widget-block .items li:first-child{
    border-top: 0;
}

.block .total-data h2,
.widget-block .total-data h2{
    margin: 0;
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
}

.block .total-data h3,
.widget-block .total-data h3{
    font-size: 18px;
    font-weight: 300;
    margin: 0;
}

.block .total-data h4,
.widget-block .total-data h4{
    font-size: 14px;
    font-weight: 300;
    margin: 0;
}

.widget-block .fact-data{
    margin: 27px 0;
}

.block .fact-data:first-child{
    border-right: 1px solid #eaeaea;
}

.block .fact-data h2,
.widget-block .fact-data h2{
    margin: 0;
    font-size: 32px;
    font-weight: 300;
}

.block .fact-data h3,
.widget-block .fact-data h3{
    text-transform: uppercase;
    margin: 0;
    font-weight: 200;
    font-size: 13px;
}

.widget-block .fact-data h3{
    margin-bottom: 13px;
    font-size: 17px;
}

.widget-block .fact-data h2{
    margin-bottom: 4px;
}

.widget-block .fact-data p{
    font-size: 12px;
}

.widget-block .no-padding{
    padding: 0;
}

.widget-block h3{
    font-size: 17px;
    margin: 5px 0 0 0;
    font-weight: 200;
}

.widget-block h2{
    font-size: 32px;
    font-weight: 300;
}

.block h2 i{
    font-size: 24px;
    color: #555;
    margin-right: 6px;
}

/*Widget Notes*/
.cl-mcont .block.widget-notes{
    border-bottom: 1px solid #e2e2e2;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
    border-radius: 3px;
}

.cl-mcont .block.widget-notes .header{
    border-bottom: 0;
}

.cl-mcont .block.widget-notes .header h4{
    margin: 0;
    font-size: 15px;
}

.cl-mcont .block.widget-notes .content{
    padding: 0;
}

.paper{
    line-height: 30px;
    font-size: 14px;
    padding: 30px 55px 27px;
    position: relative;
    outline: none;
    background: white;
    -webkit-background-size: 100% 30px;
    -moz-background-size: 100% 30px;
    -ms-background-size: 100% 30px;
    background-size: 100% 30px;
}

.paper::after{
    content: '';
    position: absolute;
    width: 0;
    top: 0;
    left: 39px;
    bottom: 0;
    border-left: 1px solid #f8d3d3;
}

/*Pie Widget*/
.pie-widget{
    margin-bottom: 20px;
}

.pie-widget .stats{
    padding-top: 20px;
    border-top: 1px solid #dadada;
}

.pie-widget .stats p{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.pie-widget .stats h5{
    margin: 0;
}

@media (max-width: 767px){
    .pie-widget .stats > div:first-child{
        margin-bottom: 20px;
    }
}

.block-flat,
.block-wizard{
    margin-bottom: 40px;
    padding: 20px 20px;
    background: #fff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    border-bottom: 1px solid #e2e2e2;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.04);
}

.block-transparent{
    margin-bottom: 40px;
}

.block-transparent .header{
    padding: 0 5px;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 15px;
}

.block-transparent .header H4{
    font-weight: 400;
}

.block-transparent .header H4 .fa{
    font-size: 14px;
}

.dropdown-menu{
    font-size: 11px;
}

.input-sm{
    height: 30px;
    padding: 5px 10px;
    font-size: 11px;
    line-height: 1.5;
    border-radius: 3px;
}

select.input-sm{
    height: 30px;
    line-height: 25px;
}

/* form field status colors */
/* input.ng-invalid.ng-touched{

} */

.inline-help-block{
    margin: -10px 0 0;
    color: red;
    display: inline-block !important;
    padding: 2px 5px 2px 10px;
    border-radius: 5px;
    font-family: verdana, sans-serif;
    font-size: 8pt;
    font-weight: normal;
}

.help-block{
    margin: -10px 0 0;
    color: red;
    display: block !important;
    padding: 2px 5px 2px 10px;
    border-radius: 5px;
    font-family: verdana, sans-serif;
    font-size: 8pt;
    font-weight: normal;
}

.vat{
    vertical-align: top !important;
}

.panel-body{
    padding: 5px !Important;
    min-height: 10px !important;
    font-weight: normal !important;
}

.nav-tabs{
    padding: 5px 0 0 0;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    background-color: #44887d  !important;
    border-bottom: 1px solid #77707b !important;

}

.panel-default > .panel-heading{
    font-weight: normal;
    color: white;
}

.nav > li > a{
    position: relative;
    display: block;
    padding: 7px 15px !important;
    background-color: #f3f3f3;
    margin: 0 2px -4px 2px !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: bold;
    border: 0;

}

.nav-tabs > li{
    float: left;
    margin-bottom: 0 !important;
    padding: 0;
}

/* unvisited link */
.nav > li > a:link{
    color: #010203;
}

/* visited link */
.nav > li > a:visited{
    color: #010203;
}

/* mouse over link */
.nav > li > a:hover{
    background-color: #6ac7b8;
    color: #010203;

}

/* selected link */
.nav > li > a:active{
    color: #010203;
}

.tabContainer{

    border: 1px solid #bcb5c6 !important;
    border-radius: 0 !important;
   
    position: relative;
    -webkit-box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.2) inset !important;
    -moz-box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.2) inset !important;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.2) inset !important;
    background: #44887d !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    font-weight: normal !important;
       /* border: 1px solid #7b7859 !important; */
    /* box-shadow: 1px 1px 2px #666 !important; */
    margin-bottom:10px !important;

}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus{
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #7b7859;
    margin-bottom: -2px;
    border-bottom-color: transparent;
}

.tab-content{

    background-color: #fff !important;
    padding: 10px;
}


.table tfoot th{
    border: 0 !important;
    font-weight: bold;

}

.table > tfoot > tr > th, .table > tfoot > tr > td{
    border: 0 !important;
    font-weight: bold;
}

.sky-mega-menu form .button{
    float: right;
    height: 32px;
    overflow: hidden;
    margin-left: 20px;
    padding: 0 10px;
    outline: none;
    border: 0;
    background-color: #44887d;
    font: 300 13px/33px 'Open Sans', Helvetica, Arial, sans-serif;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    -ms-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -webkit-transition: opacity 0.2s;
}

.form-control{
    display: inline-block !important;
    border-radius: 4px !important;
}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.input-group-addon{
    border: 2px solid;
    padding: 0 10px !important;
    border-color: #505155 !important;
    background-color: #505155 !important;
    color: #fff;

}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak{
    display: none !important;
}

.modal-content{
    background-color: #fff !important;
    border-radius: 3px;
    padding: 7px !important;
    border: 0 !important;
    -webkit-box-ow: none !important;
    box-shadow: 0 0 4px #666 !important;
}

.pagination{
    display: inline-block;
    padding: 3px;
    margin: 5px 0;
    border-radius: 4px;
    background-color: #f1f1f1;
}

.pagination-label{
    font-weight: bold;
    font-size: 8pt;
    width: 300px;
    padding: 5px 5px 3px 5px;
    margin: 0 auto -5px auto;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #f1f1f1;
}

.alert-info{
    background: #ffffff none !important;
    border: none !important;
    border-radius: 0;
    color: #515c61 !important;
}
.alert-white {
    background: #ffffff none !important;
    color: #515c61 !important;
}

.navbar-default{
    background-color: #f8f8f8;
    border: none;
    box-shadow: none !important;
}

.navbar-default .navbar-brand{
    color: #777;
    padding: 2px 10px;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus{
    color: #44887d ;
    background-color: transparent;
}

.navbar-default .navbar-text{
    color: #777;
}

.navbar-default .navbar-nav > li{
    font-size: 9pt;
    line-height: 40px;
    text-align: left;
    padding: 0.5rem 0;
    background-color: #f1f1f1;   
    border-left: 1px solid #e5e5e5;
}

.navbar-default .navbar-nav > li i{
    letter-spacing: 5pt;
    font-size: 12pt;
    line-height: 16pt;
}

.navbar-default .navbar-nav > li > a{
    color: #576267;   
    padding: 10px;
    font-weight: normal;        
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus{
    color: #fff;
    background-color: #44887d ;
    transition: background 0.4s, color 0.4s;
    -o-transition: background 0.4s, color 0.4s;
    -ms-transition: background 0.4s, color 0.4s;
    -moz-transition: background 0.4s, color 0.4s;
    -webkit-transition: background 0.4s, color 0.4s;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus{
    color: #fff;
    background-color: #44887d ;
}

.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus{
    color: #ccc;
    background-color: transparent;
}

.navbar-default .navbar-toggle{
    border-color: #ddd;
    background-color: #44887d ;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus{
    background-color: #44887d  !important;
}

.navbar-default .navbar-toggle .icon-bar{
    background-color: #fff;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form{
    border-color: #e7e7e7;
    background-color: #f1f1f1;
    margin-top: 0;
    border-radius: 0;
}

.navbar-default .navbar-nav > .open > ul{
    transition: background 0.4s, color 0.4s;
    -o-transition: background 0.4s, color 0.4s;
    -ms-transition: background 0.4s, color 0.4s;
    -moz-transition: background 0.4s, color 0.4s;
    -webkit-transition: background 0.4s, color 0.4s;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus{
    color: #fff;
    background-image: none !important;
    background-color: #44887d  !important; 
    box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);   
}

.navbar-default .navbar-form{
    margin-top: 5px;
    background-color: transparent;
    box-shadow: none;
}

.navbar-form select{
    display: inline;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 21px;
    padding: 8px 10px;
    outline: none;
    border: 1px solid #ccc !important;
    border-radius: 0;
    background: #fff;
    font: 13px 'Open Sans', Helvetica, Arial, sans-serif;
    color: #404040;
}

.navbar-form .searchTextBox{
    display: inline;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 33px;
    padding: 8px 10px;
    outline: none;
    border: 1px solid  #ccc !important;
    border-radius: 0;
    background: #fff;
    font: 14px 'Open Sans', Helvetica, Arial, sans-serif;
    color: #404040;
}

.navbar-nav .sub{
    margin-left: 10px;
    margin-top: 5px;
    padding-left: 15px;
    font-size: 8pt;
}

.navbar-form .form-group > .form-control, .navbar-form .input-group > .form-control{
    padding: 1px 7px;
}

a.search-collapse:before{
    color: #fff;
    background-color: #44887d;
    background-image: -webkit-linear-gradient(top, #22665b 0%, #44887d 100%);
    background-image: -o-linear-gradient(top, #22665b 0%, #44887d 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#22665b), to(#44887d));
    background-image: linear-gradient(to bottom, #22665b 0%, #44887d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff22665B', endColorstr='#ff44887D', GradientType=0);
    background-repeat: repeat-x;
    -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);
    box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);
    transition: background 0.4s, color 0.4s;
    -o-transition: background 0.4s, color 0.4s;
    -ms-transition: background 0.4s, color 0.4s;
    -moz-transition: background 0.4s, color 0.4s;
    -webkit-transition: background 0.4s, color 0.4s;
}

a.search-collapse.collapsed:before{
    background-color: #f3f3f3;
    margin: 0 2px -4px 2px !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: bold;
    border: 0;
}

.dropdown-submenu{
    position: relative;
}

.dropdown-submenu > .dropdown-menu{
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
    min-width:200px;
    transition: background 0.4s, color 0.4s;
    -o-transition: background 0.4s, color 0.4s;
    -ms-transition: background 0.4s, color 0.4s;
    -moz-transition: background 0.4s, color 0.4s;
    -webkit-transition: background 0.4s, color 0.4s;
}

.dropdown-submenu:hover > .dropdown-menu{
    display: block;
}

.dropdown-submenu > a:after{
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right-width: 0;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;

}

.dropdown-submenu:hover > a:after{
    border-left-color: #fff;
}



.dropdown-submenu.pull-left > .dropdown-menu{
    left: -100%;
    margin-left: 10px;


}

@media (max-width: 767px){
    .navbar-default .navbar-nav .open .dropdown-menu > li > a{
        color: #777;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus{
        color: #fff;
        background-color: transparent;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus{
        color: #555;
        background-color: #e7e7e7;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus{
        color: #ccc;
        background-color: transparent;
    }

    .search-area .input-group{
        display: inline !important;
    }
}

@media (min-width: 768px){
    .navbar-nav.navbar-right:last-child{
        margin-right: 0;
    }
}

.navbar-default .navbar-link{
    color: #777;
}

.navbar-default .navbar-link:hover{
    color: #333;
}

@media (min-width: 768px){
    .navbar-toggle{
        display: none;
    }
}

@media (max-width: 767px){
    .navbar-nav .open .dropdown-menu{
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none;
    }

    .navbar-nav .open .dropdown-menu > li > a,
    .navbar-nav .open .dropdown-menu .dropdown-header{
        padding: 5px 15px 5px 25px;
    }

    .navbar-nav .open .dropdown-menu > li > a{
        line-height: 20px;
    }

    .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-nav .open .dropdown-menu > li > a:focus{
        background-image: none;
    }
}

@media (max-width: 768px){
    .navbar-header{
        float: none;
        background-color: #f1f1f1;
    }
    .navbar-toggle{
        display: block;
    }

    .navbar-collapse{
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
        overflow-x: visible;
    }

    .navbar-collapse.in{
        overflow-x: visible;
        overflow-y: auto !important;
    }

    .navbar-collapse.collapse{
        display: none !important;
    }

    .navbar-collapse.collapse.in{
        display: block !important;
    }

    .navbar-nav{
        float: none !important;
        margin: 8px 0;
    }

    .navbar-nav > li{
        float: none;
    }

    .navbar-nav > li > a{
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .navbar-collapse.collapse.in{
        display: block !important;
        overflow-y: auto;
    }

    .navbar-form{
        width: auto;
        padding: 10px 15px;
        margin: 0 -15px;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1), 0 1px 0 rgba(255, 255, 255, .1);
    }

    .navbar-form .form-group{
        max-width: 320px;
    }

    .navbar-form .form-group > .form-control, .navbar-form .input-group > .form-control{
        max-width: 120px !important;
        font-size: 7pt !important;
        padding: 3px 7px;
    }

    .navbar-form .form-control{
        display: inline;
        width: auto;
        height: auto;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background: #fff none;
        border: 1px solid rgb(204, 204, 204);
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }

    .navbar-form select{
        min-width: 80px;
        height: 21px !important;
        min-height: 21px !important;
    }

    .navbar-header{
        float: none;
        background-color: #f1f1f1;
        border-radius: 5px 0 5px 0;
    }

    .form-label{
        text-align:left !important;
    }
}
@media (min-width: 1200px){
    .container {
        width: 100%;
    }
}

.appNote .media-body{

    vertical-align: middle;
    text-align:left;
}
.appNote .media-left{
    vertical-align: middle;
    font-size:25px;
    padding-right:1em;
    color: rgba(170, 142, 26, 0.38);
}



@-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }
  
  @-webkit-keyframes fadeInDownBig {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -2000px, 0);
      transform: translate3d(0, -2000px, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  @-webkit-keyframes zoomIn {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    50% {
      opacity: 1;
    }
  }
  
  @keyframes zoomIn {
    from {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    50% {
      opacity: 1;
    }
  }
  
  .zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
  }
  