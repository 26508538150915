
.submitForm{
    font-size:8pt !important;
}

.appNote{    
    color: #010203;
    background-color: #f1efc9;
    border: 0;    
    border-radius: 3px;
    width:100%;   
    list-style-type: none;
    margin: 0 0 5px 0 !important;
    padding: 0 !important;
}

.appNote li{   
    text-align:left;     
    display: table-cell;
    vertical-align: middle;       
}

.appNote li span{
    padding:7px 10px !important;
    text-align:center !important;    
    color: darkkhaki;
}

.submitForm .recordToolbar{
    padding: 5px;
    margin-bottom: 5px !important;
    border-radius: 3px;
    background-color:#f1f1f1;   
    min-height:33px; 
}
.submitForm .recordToolbar button{
    background-color:#38878f !important;
    color: white;
    border: 0;
    padding:2px 10px;    
    border-radius:2px;
    min-width:85px;
}
.submitForm .recordToolbar button:hover{
    background: #45a5af !important;
}

.submitForm .recordToolbar .title{
    padding-top:5px;
    text-align:center;    
    font-weight:bold; 

}

.submitForm .recordToolbar .count{
    background-color:#4d9388;
    color:#fff;
    font-weight:bold;   
    border-radius:2px;
    display:inline-block;
    min-width:85px;
    padding:6px 10px;
    float:right;
    text-align:center;
}

.submitForm .recordToolbar button:hover{
    background-color:#9cbebd;
}
.submitForm .recordToolbar button i{
    padding: 6px 6px 6px 0;
}

@media screen and (max-width: 1200px) {
    .submitForm .caption {
        text-align: left !important;
        margin-left:20px !important;
    }
}

.submitForm .recordToolbar .row{
    padding:1px !important;
}

.submitForm .row{
  padding:1px !important;
  margin:0 !important;
}
.submitForm .caption{
    text-align:right;
    color:#000;
    padding: 5px 5px;
    margin:0;
    vertical-align:middle;
}
.submitForm .how{
    text-align:center;
    background-color: #b8dfde;
    border-radius: 3px;
    padding:6px;
}



.submitForm .row{
    padding:1px;
}
.submitForm label{
    width:100% !important;
    margin:0 !important;
    background-color:#f1f1f1;
    padding:5px !important;
    border:2px solid #f1f1f1 !important;
    border-radius: 3px !important;    
    font-weight:normal;
    line-height: 1.3333333;
    empty-cells: show;

    
}
.submitForm input{
    width:100% !important;
    margin:0 !important;
    background-color: #f1f1f1;
    padding:5px !important;
    border: 1px solid #f2dede !important;
    border-radius: 3px !important;
    min-height: 26px;    
    line-height: 1.3333333;
}
.submitForm input:focus, .submitForm select:focus{
    outline: none !important;
    background-color: rgba(162, 247, 200, 0.4) !important;
    box-shadow: 0 0px 5px rgb(162, 247, 200)  !important;
    border: 1px solid rgb(149, 226, 218)  !important;
}

.submitForm select {
    -webkit-appearance: menulist;
    box-sizing: border-box;
    align-items: center;   
    white-space: pre;
    -webkit-rtl-ordering: logical;
    color: black;
    background-color: #f1f1f1;
    cursor: default;
    border-width: 2px !important;
    border-style: solid;
    
    border-radius: 3px !important;
    padding:5px;
    height: 26px;
    width:100%;    
    line-height: 1.3333333;
    border: 1px solid #f2dede !important;
}

.submitForm .filter input {
    width: 100% !important;
    margin: 0 !important;
    background-color: #fff;
    padding: 5px !important;
    border: 1px solid #ccc !important;
    border-radius: 3px !important;
    min-height: 26px;
    line-height: 1.3333333;
}
