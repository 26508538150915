html,
body{

    display: block !important;
    font-size: 11px;
    font-family: 'Open Sans', sans-serif;
    background: url("../img/ipadbk.png") repeat;
    line-height: 1.42857143;
    margin: 0;
    padding: 5px;
    height: 100%;
}


div{
    display: block;
}

meta{
    display: none;
}

.mainHeader{
    padding: 10px 10px 0 10px;
    margin: 0 10px 0 10px !important;
    background: url("../img/ipadbk.png") repeat;
    z-index: 999;
}

.mainContent{
    height: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #f1f1f1;
    margin-left: -15px;
    margin-right: -15px;
    opacity: 0.6;
}

@media screen and (max-width: 768px) {
    .displayLabel {
        text-align: left !important;
    }
}

.displayLabel{

    font-weight: normal;
    padding: 4px 10px 4px 20px;
    text-align: right;
    background-color: #fff;
    min-width:115px;
    white-space: normal;
}

.displayField{

    font-weight: bold;
    border-bottom: 1px solid #ccc;
    padding: 5px 6px 1px 6px;
    min-width: 80px;
    background-color: #fff;
    min-height:23px;
    background-color: rgba(237, 237, 237, 0.39);
}

.spacer{
    padding: 5px;
}
.fixednote{
    min-height: 400px;
    max-width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -150px;
    z-index: 100;
}

.container-border{
    background-color: #fff;
    padding: 5px;
    border: 0 !important;
    border-radius: 0px !important;

}

.trans{
    background: rgba(255, 255, 255, 0.30);
}

.container-top{
    padding: 4px;
    margin-top: 11px;
    background-color: #ffffff;
    border-radius: 0;
}


.container-body{
    padding: 5px 0;
    background-color: #fff;
    margin: 0 10px 10px 10px;
}

.container-headerFixed{
    margin-top: 110px;
}

.container-border .row{
    padding: 5px !important;
}

.nolist{
    list-style: none none;
}

#page{
    display: table;
    overflow: hidden;
    margin: 0 auto;
}

*:first-child + html #page{
    position: relative;
}

/*ie7*/
* html #page{
    position: relative;
}

/*ie6*/

#content_container{
    display: table-cell;
    vertical-align: middle;
    min-height: 450px;
}

*:first-child + html #content_container{
    position: absolute;
    top: 50%;
    min-height: 450px;
}

/*ie7*/
* html #content_container{
    position: absolute;
    top: 50%;
    min-height: 450px;
}

/*ie6*/

*:first-child + html #content{
    position: relative;
    top: -50%;
    min-height: 450px;
}

/*ie7*/
* html #content{
    position: relative;
    top: -50%;
    min-height: 450px;
}

/*ie6*/

#page{
    height: 100%;
   
}
#page-wide{
    height: 100%;
    width: 800px;
    display: table;
    overflow: hidden;
    margin: 0 auto;
}

.options{
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 10px;
}

.options2{
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    padding: 10px 10px;
    border-radius: 5px;
    margin: 5px;
}

/* .options:hover{

} */

.options li{
    padding-right: 15px;
    list-style: none;
    padding-left: 0;
}

.options input{
    margin-bottom: 10px;
}

.options label{
    padding-left: 5px;
    font-weight: bold;
}

.bodyHeight{
    min-height: 80vh !important;
}

input{
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}

input:focus + div{
    display: block;
}

input[type="text"]:focus, input[type="checkbox"]:focus, input[type="radio"]:focus, select:focus, textarea:focus{
    background-color: rgba(162, 247, 200, 0.4);
}

.fldNote{
    padding: 6px 10px;
    background-color: #c6e5ff;
    margin-top: -9px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: none;
    margin-bottom: 5px;
}

.options .option{
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 10px;
}

.recordCounter{
    padding: 6px 8px;
    color: #fff;
    font-size: 9pt;
    background-color: #646764;
    font-weight: normal;
    border-radius: 3px;
}

.recordCounter b{
    color: lightgreen;
    padding-right: 3px;
    font-weight: bold;
}

.site-body{
    padding: 10px;
    display: inline-table;
    border-radius: 5px;
    background-color: red;
    height: 100%;
    width: 100%;
}

.middle-box{
    height: 500px;
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -200px;
    z-index: 100;
}

.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.itm-desc{
    font-size: 10px;
    opacity: 0.7;
}

.main-alert{
    padding: 5px;
    margin: 10px 0 0 0;
    border-radius: 0;
    position: relative;
    z-index: 99999;

}

.alert-hidden{
    visibility: hidden;
}

/* unvisited link */
a:link{
    color: #44887d;
}


/* visited link */
a:visited{
    color: #44887d;
  
}
  /* #aaabfb */
/* mouse over link */


/* selected link */
a:active{
    color: #409299;
}

.underline{
    border-bottom: 1px solid #ccc;
}

.glass{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6f8f9+0,e5ebee+50,d7dee3+51,f5f7f9+100;White+Gloss */
    background: rgb(246, 248, 249); /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y2ZjhmOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2U1ZWJlZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUxJSIgc3RvcC1jb2xvcj0iI2Q3ZGVlMyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNWY3ZjkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(246, 248, 249, 1) 0%, rgba(229, 235, 238, 1) 50%, rgba(215, 222, 227, 1) 51%, rgba(245, 247, 249, 1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(246, 248, 249, 1)), color-stop(50%, rgba(229, 235, 238, 1)), color-stop(51%, rgba(215, 222, 227, 1)), color-stop(100%, rgba(245, 247, 249, 1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(246, 248, 249, 1) 0%, rgba(229, 235, 238, 1) 50%, rgba(215, 222, 227, 1) 51%, rgba(245, 247, 249, 1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(246, 248, 249, 1) 0%, rgba(229, 235, 238, 1) 50%, rgba(215, 222, 227, 1) 51%, rgba(245, 247, 249, 1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(246, 248, 249, 1) 0%, rgba(229, 235, 238, 1) 50%, rgba(215, 222, 227, 1) 51%, rgba(245, 247, 249, 1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(246, 248, 249, 1) 0%, rgba(229, 235, 238, 1) 50%, rgba(215, 222, 227, 1) 51%, rgba(245, 247, 249, 1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6f8f9', endColorstr='#f5f7f9', GradientType=0); /* IE6-8 */
}

.org{
    color: #000;
    font-weight: bold;
    min-height: 30px;
}

.org .comp{
    background-color: transparent;
    color: #0a3a48;
    padding: 2px 10px 2px 2px;
    border-radius: 3px;
    font-weight: normal;
    margin-left: 2px;
}

.org .user{
    background-color: transparent;
    color: #0a3a48;
    padding: 2px 10px 2px 2px;
    border-radius: 3px;
    margin-right: 5px;
    font-weight: normal;
}

.table .ctr{
    border-right: 1px solid #ddd;
    text-align: right;
    width: 30px;
}

.recordBar{
    padding: 5px 5px 5px 0;
    margin-bottom: 10px;
    background-color: #f1f1f1;
    border-radius: 4px;
    font-size: 9pt;
    font-weight: bold;
}

.table tbody tr td{
    vertical-align: top !important;
}

.table-sublist{
    border-bottom: solid 1px #ddd;
}

.form-label{
    text-align :right;
}
.recordBar .counter{
    background-color: #525252;
    padding: 3px 7px;
    border-radius: 3px;
    width: 120px;
    color: #fff;
    text-align: center;
}

.recordBar .counter .val{
    color: #00bc00;
    padding: 0 4px;
    font-weight: bold;

}

.recordBar .download{
    background-color: #696969;
    padding: 0;
    border-radius: 5px;
    margin-left: -5px;
    font-weight: normal !important;
    color: #fff;
    float: left;
}

.recordBar .banner{
    text-align: center;
    margin-top: 2px;
}

.recordBar .title{
    font-weight: normal !important;
    border: 1px solid #525252;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #fff !important;
    background-color: #525252;
    padding: 1px 10px;
    cursor: auto !important;

}

.recordBar .titleBtn{
    font-weight: normal !important;
    border: 1px solid #525252;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #fff !important;
    background-color: #525252;
    cursor: hand;
    padding: 1px 10px;

}

.recordBar .download .pdf{
    background-color: #8b0000;
    border: 1px solid #8b0000;
    width: 60px;
    color: #fff !important;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a90329+0,8f0222+44,6d0019+100;Brown+Red+3D */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2E5MDMyOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjQ0JSIgc3RvcC1jb2xvcj0iIzhmMDIyMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM2ZDAwMTkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(169, 3, 41, 1) 0%, rgba(143, 2, 34, 1) 44%, rgba(109, 0, 25, 1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(169, 3, 41, 1)), color-stop(44%, rgba(143, 2, 34, 1)), color-stop(100%, rgba(109, 0, 25, 1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(169, 3, 41, 1) 0%, rgba(143, 2, 34, 1) 44%, rgba(109, 0, 25, 1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(169, 3, 41, 1) 0%, rgba(143, 2, 34, 1) 44%, rgba(109, 0, 25, 1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(169, 3, 41, 1) 0%, rgba(143, 2, 34, 1) 44%, rgba(109, 0, 25, 1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(169, 3, 41, 1) 0%, rgba(143, 2, 34, 1) 44%, rgba(109, 0, 25, 1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a90329', endColorstr='#6d0019', GradientType=0); /* IE6-8 */

}

.recordBar .download .pdf:hover{
    background: #c60000 none;
    border: 1px solid #c60000;

}

.recordBar .download .xls{
    background-color: #bfd255;
    border: 1px solid #bfd255;
    width: 70px;
    color: #fff !important;

    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2JmZDI1NSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzhlYjkyYSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUxJSIgc3RvcC1jb2xvcj0iIzcyYWEwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM5ZWNiMmQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(191, 210, 85, 1) 0%, rgba(142, 185, 42, 1) 50%, rgba(114, 170, 0, 1) 51%, rgba(158, 203, 45, 1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(191, 210, 85, 1)), color-stop(50%, rgba(142, 185, 42, 1)), color-stop(51%, rgba(114, 170, 0, 1)), color-stop(100%, rgba(158, 203, 45, 1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(191, 210, 85, 1) 0%, rgba(142, 185, 42, 1) 50%, rgb(114, 170, 0) 51%, rgba(158, 203, 45, 1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(191, 210, 85, 1) 0%, rgba(142, 185, 42, 1) 50%, rgba(114, 170, 0, 1) 51%, rgba(158, 203, 45, 1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(191, 210, 85, 1) 0%, rgba(142, 185, 42, 1) 50%, rgba(114, 170, 0, 1) 51%, rgba(158, 203, 45, 1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(191, 210, 85, 1) 0%, rgba(142, 185, 42, 1) 50%, rgba(114, 170, 0, 1) 51%, rgba(158, 203, 45, 1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bfd255', endColorstr='#9ecb2d', GradientType=0); /* IE6-8 */

}

.recordBar .download .xls:hover{
    background: #070 none;
    border: 1px solid #070;
}

.holder{
    margin-top: 5px;
    margin-bottom: 15px;
    width: 100%;
    height: 100%;
    border: 1px solid #998ea7bf !important;
    background-color: #fff;
    border-radius: 0;
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.2) inset !important;
    -moz-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.2) inset !important;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.2) inset !important;
}

.holder-header{
    background-color: #f9f9f9;
    padding: 5px 10px !important;
    margin: -10px -10px 0 -10px;
    border-bottom: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    height: 90px;

}

.holder .header{
    padding: 10px 15px 10px 15px;
    color: #fff;
    font-size: 10pt !important;
    border-radius: 0;
    font-weight: 300;
    line-height: 1.6;
    opacity: 0.7;

}

.holder .headerJob{
    padding: 2px 15px 2px 15px;

    background-color: #ccc;
    color: #000;
    font-size: 9pt !important;
    border-radius: 0;
    font-weight: 600;
    line-height: 2;
    opacity: 0.7;
}

.holder .header:hover{

    cursor: pointer;
    opacity: 0.9;
}

.holder .headerJob:hover{
    cursor: pointer;
    opacity: 0.9;
}

.holder .body{
    background-color: #fff;
    padding: 5px 5px 5px;
}

.holder .padding{
    padding: 3px 10px 15px;
}

.holder .body .item{
    padding: 10px;
    border-radius: 0;
    background-color: #f9f9f9;
    border-bottom: none;
    margin-bottom: 5px;
}

.holder .body .itemJob{
    padding: 3px;
    border-radius: 0;
    background-color: #fff;
    border-bottom: none;
    margin-bottom: 5px;
}

.holder .body .item:hover{
    background-color: #f2f2f2;
    border-radius: 5px;

}

.holder .body .item2{
    background-color: #f2f2f2;
    padding: 10px;
    border-bottom: none;
    margin-bottom: 5px;
    border-radius: 5px;
}

.holder .body .item a{
    text-decoration: none;
    font-weight: bold;
}

.flat-holder{
    display: inline-block;
    background-color: #f8f8f8;
    border: 1px solid #c8c8c8;
    border-top-color: #dedfe0;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.08);
    color: #333;
    cursor: pointer;
    font-family: 'Open Sans', Verdana, sans-serif;
    font-size: 10px;
    margin-right: 1.8%;
    margin-bottom: 5px;
    margin-top: 10px;
    border-radius: 0;
    padding: 6px 9px 15px;
    min-width: 100px;
    transition: border-color 0.21s ease-out 0s;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6f8f9+0,e5ebee+50,d7dee3+51,f5f7f9+100;White+Gloss */
    /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y2ZjhmOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2U1ZWJlZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUxJSIgc3RvcC1jb2xvcj0iI2Q3ZGVlMyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmNWY3ZjkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top, rgba(246, 248, 249, 1) 0%, rgba(229, 235, 238, 1) 50%, rgba(215, 222, 227, 1) 51%, rgba(245, 247, 249, 1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(246, 248, 249, 1)), color-stop(50%, rgba(229, 235, 238, 1)), color-stop(51%, rgba(215, 222, 227, 1)), color-stop(100%, rgba(245, 247, 249, 1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(246, 248, 249, 1) 0%, rgba(229, 235, 238, 1) 50%, rgba(215, 222, 227, 1) 51%, rgba(245, 247, 249, 1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(246, 248, 249, 1) 0%, rgba(229, 235, 238, 1) 50%, rgba(215, 222, 227, 1) 51%, rgba(245, 247, 249, 1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(246, 248, 249, 1) 0%, rgba(229, 235, 238, 1) 50%, rgba(215, 222, 227, 1) 51%, rgba(245, 247, 249, 1) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(246, 248, 249, 1) 0%, rgba(229, 235, 238, 1) 50%, rgba(215, 222, 227, 1) 51%, rgba(245, 247, 249, 1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6f8f9', endColorstr='#f5f7f9', GradientType=0); /* IE6-8 */

}

.flat-holder:hover{
    border: 1px solid #44887d;
    background-color: #f3f3f3;

}

.flat-holder h2{

    font-weight: 400;
    font-size: 8pt;

}

.flat-holder .label{
    font-size: 14px;
    padding: 3px 10px !important;
    font-weight: 500;
    background-color: #205156;
    -moz-box-shadow: inset 0 2px 2px #000;
    -webkit-box-shadow: inset 0 2px 2px #000;
    box-shadow: inset 0 2px 2px #000;
    color: #fff;

}

.recordHeader{
    padding: 10px 15px 15px 5px !important;
}

.recordHeader table{
    width: 100%;
}

.recordHeader img{
    width: 170px;
    height: 110px;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 0px !important;

    /*box-shadow: 0 0 10px black;*/

}

.recordHeader table th{
    font-weight: normal;
    padding: 3px 10px;
    text-align: left;
    line-height: 1.6em;
    vertical-align: top;
}

.recordHeader td{
    font-weight: bold;
    border-bottom: 1px solid #ccc;
    padding: 3px 6px;
    text-align: left;
    vertical-align: top;
    min-width: 80px;
    background-color: #fff;
}

.mynav{
    top: 0;
    position: fixed;
    left: 0;
    right: 0;
    margin-top: 20px;
    margin-left: 35px;
    margin-right: 35px;
    z-index: 1030;
}

.msg{
    top: 35vh;
    text-align: center;
    opacity: 1 !important;
    margin: auto;
    width: 350px !important;
}

.input-exsm{
    height: 28px;
    padding: 2px 10px 2px 10px;
    font-size: 12px;
    line-height: 1.3333333;
    border-radius: 2px;
}

.divider{
    height: 1px;
    width: 100%;
    display: block; /* for use on default inline elements like span */
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}

/* PHOTOS  */
.photosHeader div{
    text-align: left;
    padding: 5px 5px 5px 5px;
    margin: 4px;
    border: solid 1px #ccc;
    width: 182px;
    height: 132px;
    float: left;
    background-color: #f8f8f8;
    font-weight: bold;
}

.photosHeader div img{
    margin: 0;
    border: solid 1px #000;
    padding: 0;
}

.photos{
    clear: both;
    font-family: 'Open Sans', Verdana, sans-serif;
    font-size: 9px;
    vertical-align: top;
    max-width: 100%;
}

.photos .no{
    background-color: red;
    color: #fff;
    padding: 3px 5px 3px 5px;
}

.photos div{
    text-align: left;
    padding: 5px 5px 5px 5px;
    margin: 4px;
    border: solid 1px #ccc;
    width: 185px;
    height: 225px;
    float: left;
    background-color: #f8f8f8;
    font-weight: bold;
}

.photosBids div{
    text-align: left;
    padding: 5px 5px 5px 5px;
    margin: 4px;
    border: solid 1px #ccc;
    width: 170px;
    height: 195px;
    float: left;
    background-color: #f8f8f8;
    font-weight: bold;
}

.photosDocPackage div{
    text-align: left;
    padding: 5px 5px 5px 5px;
    margin: 1px;
    border: solid 1px #ccc;
    width: 165px;
    height: 250px;
    float: left;
    background-color: #f8f8f8;
    font-weight: bold;
}

.photos div img{
    margin: 0;
    border: solid 1px #000;
    padding: 0;
}

.ph{
    padding: 4px;
    background-color: #f5f5f5;
    font-weight: bold;
    -webkit-border-radius: 4px 6px 4px 4px;
    -moz-border-radius: 4px 6px 4px 4px;
    border-radius: 4px 6px 4px 4px;
    width: 200px;
    height: 195px;
    font-family: Verdana, Helvetica, sans-serif;
    font-size: 9px;
    cursor: pointer;
}

.phBids{
    padding: 4px;
    background-color: #f5f5f5;
    font-weight: bold;
    -webkit-border-radius: 4px 6px 4px 4px;
    -moz-border-radius: 4px 6px 4px 4px;
    border-radius: 4px 6px 4px 4px;
    width: 170px;
    height: 195px;
    font-family: Verdana, Helvetica, sans-serif;
    font-size: 9px;
    cursor: pointer;
}

.phDocPackage{
    padding: 4px;
    background-color: #f5f5f5;
    font-weight: bold;
    -webkit-border-radius: 4px 6px 4px 4px;
    -moz-border-radius: 4px 6px 4px 4px;
    border-radius: 4px 6px 4px 4px;
    width: 160px;
    height: 220px;
    font-family: Verdana, Helvetica, sans-serif;
    font-size: 9px;
    cursor: pointer;
}

.phHeader{
    padding: 4px;
    background-color: #f5f5f5;
    font-weight: bold;
    -webkit-border-radius: 4px 6px 4px 4px;
    -moz-border-radius: 4px 6px 4px 4px;
    border-radius: 4px 6px 4px 4px;
}

.ph p{
    padding: 4px;
    margin: 0;
}

.ph .thumbnail{
    margin: 0 !Important;
}

.ph input{
    float: left;
    margin: -30px 0 0 8px;
    position: relative;
    padding: 0;
}

/* .ftr{

} */

.ftr a{
    padding: 5px;
}

.form-body{
    padding: 10px;
    border-radius: 5px;
    background-color: #f1f1f1;
}

table .filter{
    border-spacing: 0;
    border-collapse: collapse;
    padding: 0 !important;
    background-color: #e6e6e6;
    vertical-align: middle !important;
}

table .filter td{
    border-spacing: 0;
    padding: 1px !important;
    border-collapse: collapse;
    vertical-align: middle !important;
}

.exportHolder{
    padding: 5px 0 5px 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
    width: 232px;

}

.exportHolder:hover{

}

.dateHeader{
    padding: 5px !important;
    vertical-align: bottom;
    margin: 0 -7px -10px -7px;
    border: 2px solid #e3e3e3;
    background-color: #e3e3e3;
    color: #666;
    font-weight: normal;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 9pt;

}

.dateRight{
    border-right: 2px solid #e3e3e3;

}

.dateLeft{
    border-left: 2px solid #e3e3e3;

}

.dateBody{

}

/*  Legend Styles  */
.legend{

    margin: 0 auto;
    padding: 0 15px 10px 15px !important;
}

.hidden{

}

.srcLoan{
    padding: 2px 5px !important;
    background-color: #d28800;
    height: 18px;
    text-align: center;
    width: 20px;
    font-weight: bold;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    color: #f1f1f1;
    font-size: 9px;
    margin-right: 3px;

}

.srcProp{
    padding: 2px 5px !important;
    background-color: #1a9a36;
    height: 18px;
    text-align: center;
    width: 20px;
    font-weight: bold;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    color: #f1f1f1;
    font-size: 9px;
    margin-right: 3px;

}

.srcJob{
    padding: 2px 3px !important;
    background-color: #75b7f3;
    height: 18px;
    text-align: center;
    width: 20px;
    font-weight: bold;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    color: #f1f1f1;
    font-size: 9px;
    margin-right: 3px;

}

.greyNote{
    background-color: #f1f1f1;
    border-bottom: 1px solid #ccc;
    padding: 10px 15px;
    margin-bottom: 5px;
    text-align: center;
}

.onoffswitch{
    position: relative;
    width: 59px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.onoffswitch-checkbox{
    display: none;
}

.onoffswitch-label{
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #999;
    border-radius: 20px;
}

.onoffswitch-inner{
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after{
    display: block;
    float: left;
    width: 50%;
    height: 18px;
    padding: 0;
    line-height: 18px;
    font-size: 10px;
    color: white;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: bold;
    box-sizing: border-box;
}

.onoffswitch-inner:before{
    content: "ON";
    padding-left: 11px;
    background-color: #45c234;
    color: #fff;
}

.onoffswitch-inner:after{
    content: "OFF";
    padding-right: 11px;
    background-color: #f52727;
    color: #fff;
    text-align: right;
}

.onoffswitch-switch{
    display: block;
    width: 10px;
    margin: 4px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 37px;
    border: 2px solid #999;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner{
    margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch{
    right: 0;
}

.spinc{

    display: block !important;
    opacity: 0.8;
    width: 100vw !important;
    height: 100vh !important;
    margin: auto auto !important;
    text-align: center !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 99999999999;
}

.top tr td{
    vertical-align: top !important;
}

.checkFilter input[type='checkbox']{
    margin: 5px;
    text-align: left;
}

.workOrderNumber{
    color: #5fe15d; 
    padding-left: 3px;
}

#searchCollapse > div{
    margin: -10px 0 -15px 0;
}

#searchCollapse > div > ul{
}

@media screen and (min-width: 769px) and (max-width: 1066px){
    .menuLabel{
        display: none;
    }
}

@media screen and (min-width: 769px){
    .menuSearchLabel{
        display: none;
    }
}

@media screen and (max-width: 769px){
    .breadcrumb{
        -webkit-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }

    .breadcrumb div{
        padding-left: 10px;
    }

   
    #searchCollapse > div{
        margin: -10px 0 -15px 0;
    }

    #searchCollapse > div > ul{
        margin: -10px 0 -17px 0;
    }
}

.panel-collapse.collapse.in > .row > ul{
    display: none;
}

.search-form-button{
    color: #fff;
    overflow: hidden;
    text-shadow: none;
    margin-left: 20px;
    padding: 1px 10px;
    outline: none;
    border: solid 1px #7b7859 !important;
    border-left: 0 !important;
    background:  #38878f none;
    box-shadow: none !important;
    font-size: 7pt;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -ms-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
}

.frmSearch table tr td, .frmSearch table tr th{
    padding-top: 10px;
}

/* .form-group .btn-default:hover, .form-group .btn-default:focus, .search-form-button:hover{ */
    /* text-decoration: none;
    background: #45a5af none;
    border: solid 1px #7b7859 !important;
    border-left: 0 !important;
    box-shadow: none !important;
    color: #fff; */
    /* transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -ms-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s; */
}

#searchIcon{
    display: inline;
}

.affix{
    top: 0;
    width: 100%;
}

.affix + .container-fluid{
    padding-top: 40px;
}


.white-tooltip + .tooltip > .tooltip-inner {background-color: #f9f9f9; width:300px; max-width: 300px;}
.white-tooltip + .tooltip > .tooltip-arrow { border-bottom-color:#ffffff; }


.btn-approve, .btn-reject, .btn-modify {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
    cursor: pointer;
}

.btn-selected {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
    cursor: pointer;
}
.btn-approve:hover {
    color: #fff;
    background-color: #418177;
    cursor: pointer;
}

.btn-reject:hover {
    color: #fff;
    background-color: #418177;
    cursor: pointer;
}

.btn-modify:hover {
    color: #fff;
    background-color: #418177;
    cursor: pointer;
}

.btn-approve:active,
.btn-approve.active,
.open > .dropdown-toggle.btn-approve {
    color: #fff;
    background-color: #00A000;
}

.btn-reject:active,
.btn-reject.active,
.open > .dropdown-toggle.btn-reject {
    color: #fff;
    background-color: red;
}

.btn-modify:active,
.btn-modify.active {
    color: #fff;
    background-color: #00A000;
}

.table-striped-double > tbody > tr:nth-of-type(4n), tr:nth-of-type(4n-1) {
    background-color: #f9f9f9;
}

.btn-xxs,
.btn-group-xxs > .btn {
    padding: 1px 5px;
    font-size: 10px;
    line-height: 1.5;
    border-radius: 3px;
}

.btn-selected:active, .btn-selected.active,
.open > .dropdown-toggle.btn-approve {
    color: #fff;
    background-color: #38878f  ;
}

.ui-grid-row:hover .ui-grid-cell {
  background-color: rgb(94, 115, 114);
    color:#fff;
  cursor: pointer;
}

.ui-grid-row:hover a{
    color: #f9f9f9;
}

.ui-grid-cell-contents {
    padding: 7px 10px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 100%;
}


.filterButton{
    color: #fff;
    background-color: #949494;
    cursor: pointer;
}

.activeBtn{
    background-color: #88a7a5;
    color: #205156 ;
    font-weight: bold;
}

.panel-heading .accordion-toggle:after {
    content: "\f077";
    font-family: 'FontAwesome';
    position: absolute;
    right : 0;
    padding-right:30px;
}

.panel-heading .accordion-toggle.collapsed:after {
    content: "\f078";
}
